import { RouterState } from 'react-router-redux'

import { Menu } from '@library/menu-service'

import { actionTypes } from 'actions/actionTypes'

export type MenuCollectionsState = {
  menuV3Collections: {
    collections: Record<string, Collection>
    slugToId: Record<string, string>
  }
  routing: RouterState
}

type V2Action = {
  type: typeof actionTypes.MENU_COLLECTIONS_RECEIVE
  collections: PayloadCollection[]
}
type V3Action = {
  type: typeof actionTypes.MENU_SERVICE_V3_DATA_RECEIVED
  response: Menu
}
type Action = V2Action | V3Action

type PayloadCollection = {
  id: string
  slug: string
  shortTitle: string
  recipesInCollection: string[]
  order: number
}

export type Collection = {
  id: string
  slug: string
  shortTitle: string
  order: number
  recipes: {
    id: string
    options: {
      id: string
    }[]
  }[]
  index: number
}

const initialState: MenuCollectionsState['menuV3Collections'] = {
  collections: {},
  slugToId: {},
}

export const menuCollectionsReducers = {
  menuV3Collections: (
    state = initialState,
    action: Action,
  ): MenuCollectionsState['menuV3Collections'] => {
    switch (action.type) {
      case actionTypes.MENU_COLLECTIONS_RECEIVE: {
        const slugToId: Record<string, string> = {}
        action.collections.forEach((collection) => {
          slugToId[collection.slug] = collection.id
        })

        return {
          slugToId,
          collections: Object.fromEntries(
            action.collections
              .map(
                (collection: PayloadCollection, index: number) =>
                  ({
                    id: collection.id,
                    slug: collection.slug,
                    shortTitle: collection.shortTitle,
                    recipes: collection.recipesInCollection.map((recipeId: string) => ({
                      id: recipeId,
                      options: [], // @TODO Remember to populate this field
                    })),
                    order: collection.order,
                    index,
                  } as Collection),
              )
              .map((collection: Collection) => [collection.id, collection]),
          ),
        }
      }
      case actionTypes.MENU_SERVICE_V3_DATA_RECEIVED: {
        const slugToId: Record<string, string> = {}
        Object.values(action.response.categories).forEach((category) => {
          slugToId[category.slug] = category.id
        })

        return {
          slugToId,
          collections: Object.fromEntries(
            Object.values(action.response.categories)
              .map((category, index) => ({
                id: category.id,
                slug: category.slug,
                shortTitle: category.short_title,
                recipes: category.recipes,
                order: category.order,
                index,
              }))
              .map((category) => [category.id, category]),
          ),
        }
      }
      default: {
        return state
      }
    }
  },
}
